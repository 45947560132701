import { compose } from './immutable'

import Cookie from 'js-cookie'
import Overlay from 'ol/Overlay'

export const extensionIcon = file => [
  [/\.pdf$/, 'extension-pdf'],
  [/\.(xls|xlsx)$/, 'extension-excel'],
  [/\.(folder)$/, 'extension-folder'],
  [/\.(doc|docx)$/, 'extension-word']
].find(([when]) => when.test(file || ''))?.[1] || 'extension'

/**
  * @param {number} x
  * @param {number} y
  * @param {DOMRect} rect
  * @param {number} indent
  */
export const belongToElementRectWithIndent = (x, y, rect, indent) => x >= rect.x - indent && x <= rect.x + rect.width + indent && y >= rect.y - indent && y <= rect.y + rect.height + indent

const values = {}

export const set = (key, value, { cookie = false } = {}) => ((values[key] = value) || true) && (cookie && Cookie.set(key, value))

export const get = (key, value, { cookie = false } = {}) => (cookie ? Cookie.get(key) : values[key]) ?? value

export const forget = (key, { cookie = false, domain } = {}) => ((delete values[key]) || true) && (cookie && Cookie.remove(key, { domain }))

const updates = {}

export const defer = (id, f, d = 2000) => {
  id = id || ''

  const i = setTimeout(() => {
    f()
    updates[id] = false
  }, d)

  updates[id] && clearTimeout(updates[id])
  updates[id] = i
}

export const defineFilterField = ({ type, key, placeholder, label, group, options, props, from, to, under, loading, disabled } = {}) => ({
  type,
  propName: key,
  placeholder,
  label,
  group,
  options,
  props,
  from,
  to,
  under,
  loading,
  disabled
})

export const selectTreeNodeById = (ref, id) => {
  id && ref.setCurrentKey(id)
}

export const expandTreeNodeById = (ref, id) => {
  id && ref.store.nodesMap[id] && (ref.store.nodesMap[id].expanded = true)
  id && expandTreeNodeById(ref, ref.store.nodesMap[id]?.parent?.data?.id)
}

export const injectCommonFieldProps = ({ defaultName, defaultLabel, defaultPlaceholder } = {}) => ({
  independent: { type: Boolean, default: false },
  name: { type: String, default: defaultName },
  label: { type: String, default: defaultLabel },
  placeholder: { type: String, default: defaultPlaceholder },
  required: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  errors: { type: Array, default: () => [] }
})

export const injectSelectFieldProps = () => ({
  asKey: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  items: { type: Array, default: null }
})

export const createErrorOverlay = (map, feature, errors) => {
  const element = document.createElement('div')
  element.className = 'bg-rock text-red-1 _p-0.5 rounded shadow-md fs-0.8'

  // build all errors to single string
  element.innerText = compose(
    x => x.join('\n'),
    x => x.length > 1 ? x.map(x => '- ' + x) : x,
    x => Object.values(x).flat()
  )(errors) 

  const tooltip = new Overlay({
    element,
    offset: [0, -16],
    positioning: 'bottom-center',
    stopEvent: false,
    insertFirst: false
  })

  tooltip.setPosition(feature.getGeometry().getInteriorPoint().getCoordinates())
  tooltip.set('id', feature.get('id'))
  tooltip.set('name', feature.get('name'))

  // remove on click
  element.onclick = removeOverlay.bind(null, tooltip)

  map.addOverlay(tooltip)

  return tooltip
} 

export const removeOverlay = x => {
  x.getElement().remove()
  x.getMap()?.removeOverlay?.(x)
}
