import { nanoid } from 'nanoid'

/**
 * @param {*[]} a - Array
 * @param {function(a: *, b: *): boolean} predicate - Equality predicate 
 */
export const unique = (a, predicate = (a, b) => a === b) => a.reduce((r, x) => [...r, ...r.find(y => predicate(x, y)) ? [] : [x]], [])

/**
 * @param {*[]} a - Set
 * @param {*[]} b - Set
 * @param {function(a: *, b: *): boolean} predicate - Equality predicate 
 */
export const difference = (a, b, predicate = (a, b) => a === b) => a.filter(x => !b.find(y => predicate(x, y)))

/**
 * @param {*[]} a - Set
 * @param {*[]} b - Set
 * @param {function(a: *, b: *): boolean} predicate - Equality predicate 
 */
export const symmetricalDifference = (a, b, predicate = (a, b) => a === b) => [ ...a.filter(x => !b.find(y => predicate(x, y))), ...b.filter(x => !a.find(y => predicate(x, y))) ]

/**
 * @param {*[]} a - Set
 * @param {*[]} b - Set
 * @param {function(a: *, b: *): boolean} predicate - Equality predicate 
 */
export const intersection = (a, b, predicate = (a, b) => a === b) => a.filter(x => b.find(y => predicate(x, y)))

/**
 * @param {*[]} a - Set
 * @param {*[]} b - Set
 * @param {function(a: *, b: *): boolean} predicate - Equality predicate 
 */
export const intersectionWithZip = (a, b, predicate = (a, b) => a === b) => a
  .map(x => {
    const found = b.find(y => predicate(x, y))

    return found && [x, found]
  })
  .filter(is)

/**
 * @param {*[]} a - Set
 * @param {*[]} b - Set
 * @param {function(a: *, b: *): boolean} predicate - Equality predicate 
 */
export const intersectionWithZipNullable = (a, b, predicate = (a, b) => a === b) => a
  .map(x => {
    const found = b.find(y => predicate(x, y))

    return [x, found]
  })

/**
 * @param {*[]} a - Set
 * @param {*[]} b - Set
 * @param {function(a: *, b: *): boolean} predicate - Equality predicate 
 */
export const union = (a, b, predicate = (a, b) => a === b) => [ ...a.filter(x => !b.find(y => predicate(x, y))), ...b ]

/**
 * @param {*[]} a - Set
 * @param {*[]} b - Set
 */
export const zip = (a = [], b = []) => a.map((x, i) => [x, b[i]])

export const equalityById = (a, b) => a.id === b.id

/**
 * Remove duplications
 * @param {*[]} a
 * @returns {*[]}
 */
export const deduplicate = a => Object.values(a.reduce((r, x) => ({ ...r, [x]: x }), {}))

/**
 * Remove duplications by key of a object property
 * @param {Object[]} a
 * @param {string} k
 * @returns {Object[]}
 */
export const deduplicateBy = (a, k) => Object.values(a.reduce((result, item) => ({ ...result, [item[k]]: item }), {}))

export const keys = function() {
  return Object.keys(this).filter(key => key !== 'values')
}

export const values = function() {
  return keys.call(this).map(key => this[key])
}

export const sortAsName = (by, order = 'asc') => order === 'asc' 
  ? (a, b) => by(a) < by(b) ? -1 : 1 
  : (a, b) => by(a) < by(b) ? 1 : -1

export const sortAsDate = (by, order = 'asc') => order === 'asc' 
  ? (a, b) => by(a)?.getTime?.() - by(b)?.getTime?.()
  : (a, b) => by(b)?.getTime?.() - by(a)?.getTime?.()

export const sortAsNumber = (by, order = 'asc') => order === 'asc' 
  ? (a, b) => by(a) - by(b) 
  : (a, b) => by(b) - by(a)

export const createArray = (x, by) => Array.from({ length: x }, by)

export const createMatrix = (x, y, by) => Array.from({ length: x }, x => Array.from({ length: y }, by && (y => by(x, y))))

export const promisify = is => new Promise((resolve, reject) => is(resolve, reject))

/**
 * @param {[] || string} if - Something containing elements (having a length)
 * @param {*} else - Value when there are no elements 
 * @returns {*}
 */
export const itemsOr = ({ if: x, else: y }) => x.length ? x : y

export const compose = (...f) => x => f.reduceRight((y, f) => f(y), x)
export const composeReverse = (...f) => x => f.reduce((y, f) => f(y), x)

/**
 * Generate a unique string key
 * @returns {string}
 */
export const key = () => nanoid()

/**
 * @param {[] || object} x
 * @returns {[] || object}
 */
export const is = x => x

export const array = (x = []) => Array.isArray(x) ? x : [x] 

export const event = () => {
  let fs = []

  return {
    emit: x => fs.map(f => f(x)),
    on: f => fs.push(f),
    onWithClear: f => (fs = fs.filter(f => f())).push(f)
  }
}

export const repeat = (count, callable) => Array.from({ length: count }, callable)

export const sleep = timeout => new Promise(handler => setTimeout(handler, timeout))

export const isEmpty = x => x === null || x === undefined || x.length === 0 || x === '' || (typeof x === 'number' && isNaN(x))
export const ifEmpty = (x, y) => isEmpty(x) ? y : x
export const ifEmptyByFn = (x, fn) => isEmpty(x) ? fn() : x

export const iterate = items => {
  let i = 0
  let max = items.length - 1

  return {
    next: () => items[i === max ? i = 0 : i = i + 1],
    index: () => i
  }
}

/**
 * Group array elements in order based on group size and return an array of arrays - groups
 * @param {[]} items - Array
 * @param {number} n - Chunk size
 * @return {[][]}
 */
export const chunk = (items, n) =>
  items.reduce((r, x, i) => {
    const j = Math.floor(i / n)
    r[j] = [...(r[j] || []), x]
    return r
  }, [])

export const range = (from, to) => Array(to - from).fill(0).map((x, y) => x + y + from)

export const first = (x = []) => x[0]
export const last = (x = []) => x[x.length - 1]
export const left = (items = [], x, predicate = (a, b) => a === b) => items[items.findIndex(y => predicate(x, y)) - 1]
export const right = (items = [], x, predicate = (a, b) => a === b) => items[items.findIndex(y => predicate(x, y)) + 1]

export const filterObjectByKeys = (x = {}, keys = []) => Object.entries(x).reduce((r, [k, v]) => ({
  ...r,
  ...intersection([k], keys).length && { [k]: v }
}), {})

export const filterEmpty = (x = {}, { by = isEmpty } = {}) => Object.entries(x).reduce((r, [k, v]) => ({
  ...r,
  ...!by(v) && { [k]: v }
}), {}) 

export const then = (x, f) => x && f(x)
export const thenOr = (x, f, d) => x ? f(x) : d
export const thenAll = (x = [], f) => !x.some(y => !y) && f(x)
