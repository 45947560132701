<template>
    <el-form-item :label="label"
                  :prop="name"
                  :error="errors.join('\n')"
                  :required="required">
        <template slot="label">
            <slot name="label" />
        </template>

        <el-date-picker :value="value"
                        :picker-options="options"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :readonly="readonly"
                        :size="size"
                        format="dd.MM.yyyy"
                        style="width: 100%"
                        type="date"
                        @input="onChange" />
    </el-form-item>
</template>

<script>
import { injectCommonFieldProps } from '@/utils/ui'
import { createDisabledDate } from '@/utils/fields'

const labels = {
  name: 'Дата',
  placeholder: 'Выберите'
}

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps(),

    value: { type: Date, default: null },
    placeholder: { type: String, default: labels.placeholder },
    size: { type: String, default: null },

    allowedFrom: { type: [Number, Date], default: null },
    allowedTo: { type: [Number, Date], default: null }
  },
  computed: {
    options() {
      return {
        firstDayOfWeek: 1,
        disabledDate: createDisabledDate({
          from: this.allowedFrom,
          to: this.allowedTo
        })
      }
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', value)
    }
  }
}
</script>
