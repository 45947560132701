<template>
    <div
        v-loading="loading">
        <el-row>
            <el-col v-if="task"
                    :span="24">
                <el-row>
                    <!-- Card -->
                    <defect-card class="mb-4"
                                 :task="task"
                                 :preview-mode="false"
                                 @photo-click="showGalleryByTask" />

                    <!-- Captures -->
                    <task-captures :task="task"
                                   @capture-photo-click="showGalleryByCapture"
                                   @capture-position-click="goToPosition" />

                    <!-- Fields -->
                    <el-col :span="dependent ? 24 : 12">
                        <el-form :model="editedItem"
                                 label-width="140px"
                                 :label-position="dependent ? 'top' : 'left'"
                                 size="mini"
                                 @submit.native.prevent>
                            <!-- Name -->
                            <input-field 
                                v-model="editedItem.name"
                                label="Название задачи"
                                placeholder="Введите название"
                                :required="isRequiredField('name')"
                                :disabled="!isEditableField('name')"
                                :errors="errors['name'] || editableFieldsErrorsByName('name')" />

                            <!-- Kind -->
                            <defect-kind-field 
                                v-model="editedItem.kind_defect"
                                :required="isRequiredField('kind_defect')"
                                :disabled="!isEditableField('kind_defect')"
                                :errors="errors['kind_defect'] || editableFieldsErrorsByName('kind_defect')" />

                            <!-- Expired at -->
                            <date-field 
                                v-model="editedItem.expired_at"
                                label="Дата завершения"
                                :required="isRequiredField('expired_at')"
                                :disabled="!isEditableField('expired_at')"
                                :errors="errors['expired_at'] || editableFieldsErrorsByName('expired_at')" />

                            <!-- Worker -->
                            <member-field 
                                v-model="members"
                                label="Исполнитель"
                                :required="isRequiredField('worker_id') || isRequiredField('worker_organization_id')"
                                :disabled="!isEditableField('worker_id')"
                                :errors="errors['worker_id'] || errors['worker_organization_id'] || errors['worker_type'] || editableFieldsErrorsByName('worker_id') || editableFieldsErrorsByName('worker_organization_id') || editableFieldsErrorsByName('worker_type')"
                                :with-contractor="hasRequiredFieldValue('worker_type', 'contractor')"
                                :with-engineer="hasRequiredFieldValue('worker_type', 'engineer')"
                                :with-contractor-or-engineer="hasRequiredFieldValue('worker_type', 'contractor') && hasRequiredFieldValue('worker_type', 'engineer')" />

                            <!-- Inspector -->
                            <user-field 
                                v-model="editedItem.inspectors"
                                label="Контроль"
                                :required="isRequiredField('general_contractor_ids')"
                                :disabled="!isEditableField('general_contractor_ids')"
                                :errors="errors['general_contractor_ids'] || editableFieldsErrorsByName('general_contractor_ids')"
                                multiple
                                is-inspector />

                            <!-- Place -->
                            <place-field 
                                v-model="editedItem.place"
                                :required="isRequiredField('house_id') || isRequiredField('floor_id')"
                                :disabled="!isEditableField('house_id') || !isEditableField('floor_id')"
                                :errors="errors['house_id'] || errors['floor_id'] || editableFieldsErrorsByName('house_id') || editableFieldsErrorsByName('floor_id')"
                                label="Относится к"
                                with-at-project />

                            <!-- Tags -->
                            <tag-field 
                                v-model="tags"
                                label="Теги"
                                :required="isRequiredField('tags')"
                                :disabled="!isEditableField('tags')"
                                :errors="errors['tags'] || editableFieldsErrorsByName('tags')" />

                            <!-- Lot defect -->
                            <lot-defect-field
                                v-model="editedItem.lot_defect"
                                label="Лот дефекта"
                                :required="isRequiredField('lot_defect_id')"
                                :disabled="!isEditableField('lot_defect_id')"
                                :errors="errors['lot_defect_id'] || editableFieldsErrorsByName('lot_defect_id')" />

                            <!-- Standard -->
                            <building-standard-field 
                                v-model="editedItem.defect_types"
                                :required="isRequiredField('defect_types')"
                                :disabled="!isEditableField('defect_types')"
                                :errors="errors['defect_types'] || editableFieldsErrorsByName('defect_types')" />

                            <!-- Standard (by input) -->
                            <input-field 
                                v-model="editedItem.custom_defect_type"
                                label="Нормативный документ (от пользователя)"
                                placeholder="Введите название дефекта"
                                :required="isRequiredField('custom_defect_type')"
                                :disabled="!isEditableField('custom_defect_type')"
                                :errors="errors['custom_defect_type'] || editableFieldsErrorsByName('custom_defect_type')" />

                            <!-- Description -->
                            <input-field v-model="editedItem.description"
                                         label="Выявленные нарушения и рекомендации по устранению"
                                         placeholder="Введите текст описания"
                                         type="textarea"
                                         :required="isRequiredField('description')"
                                         :disabled="!isEditableField('description')"
                                         :errors="errors['description'] || editableFieldsErrorsByName('description')" />

                            <!-- Comment (initiator) -->
                            <input-field v-model="editedItem.comment_initiator"
                                         label="Комментарий к добавлению дефекта"
                                         placeholder="Введите текст комментария"
                                         type="textarea"
                                         :required="isRequiredField('comment_initiator')"
                                         :disabled="!isEditableField('comment_initiator')"
                                         :errors="errors['comment_initiator'] || editableFieldsErrorsByName('comment_initiator')" />

                            <!-- Worker comment -->
                            <input-field v-model="editedItem.comment_worker"
                                         label="Комментарий подрядчика"
                                         type="textarea"
                                         :required="isRequiredField('comment_worker')"
                                         :errors="errors['comment_worker'] || editableFieldsErrorsByName('comment_worker')"
                                         disabled />

                            <!-- Photos -->
                            <file-field v-if="editablePhoto"
                                        v-model="photos"
                                        label="Фотография"
                                        :modal="!dependent"
                                        :errors="errors['task_image_ids'] || errors['image_defining_point_id'] || errors['image'] || errors['images.0.image'] || editableFieldsErrorsByName('task_image_ids') || editableFieldsErrorsByName('image_defining_point_id')"
                                        with-camera />

                            <div v-if="connectedTasks.length"
                                 class="f">
                                <div class="mb-5">
                                    <span class="text-size--body text-color--label pr-3"
                                          style="display: inline-block; width: 140px; box-sizing: border-box">
                                        Связанные задачи:
                                    </span>
                                </div>
                                <div>
                                    <div class="f-col">
                                        <div v-for="order in connectedTasks"
                                             :key="order.id"
                                             class="mb-1">
                                            <router-link class="text-size--body text-color--primary text-decoration--none"
                                                         :to="{ name:'project.task', params:{ taskId: order.task_object_id } }">
                                                {{ getNested(order, 'task_object.name') }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Buttons -->
                            <div class="cols-minmax-min space-x-1">
                                <!-- Confirm -->
                                <el-button type="primary"
                                           :disabled="!hasRequiredFields"
                                           @click="update">
                                    Сохранить
                                </el-button>

                                <!-- Actions (dependent) -->
                                <task-actions v-if="dependent"
                                              :task="task"
                                              :actions="allowedActions"
                                              as-dropdown
                                              @do-with-data="act" />
                            </div>
                        </el-form>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <!-- Gallery -->
        <task-photos-gallery v-if="shouldDisplayGallery"
                             :media="media"
                             :show-gallery="shouldDisplayGallery"
                             :show-caption="shouldDisplayCaption"
                             :position="galleryIndex"
                             editable
                             @on-close-gallery="hideGallery"
                             @image-select="selectGalleryImage" />

        <!-- Actions (default) -->
        <portal v-if="!dependent"
                to="allowed-actions">
            <task-actions 
                :task="task"
                :actions="allowedActions"
                @do-with-data="act" />
        </portal>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { formatForServer, parseAt } from '@/utils/date'
import { startOfDay } from 'date-fns'

import { urlToFile } from '@/utils/browser'
import { fromError, flatCollectionError } from '@/utils/common';

import { getPlanIdFromPosition, getPointIdFromPosition, getPhotoIdFromPosition } from '@/models/projects'
import { 
  getInspectorUsers, getContractorUser, 
  getWorkerComment, getCaptures, 
  getMembersForSelection, 
  toWorkerUpdation, toImageUpdation 
} from '@/models/tasks'

import { loadable } from '@/store/connectors'

import InputField from '@/components/fields/InputField'
import TaskActions from '@/components/tasks/TaskActions'
import DateField from '@/components/fields/DateField'
import UserField from '@/components/fields/UserField'
import BuildingStandardField from '@/components/fields/BuildingStandardField'
import DefectKindField from '@/components/fields/DefectKindField'
import PlaceField from '@/components/fields/PlaceField'
import FileField from '@/components/fields/FileField'
import TagField from '@/components/fields/TagField'
import MemberField from '@/components/fields/MemberField'
import LotDefectField from '@/components/fields/LotDefectField'

import DefectCard from '@/components/task/DefectCard';
import TaskPhotosGallery from '@/components/task/TaskPhotosGallery'
import TaskCaptures from '@/components/task/TaskCaptures'

export default {
  name: 'TaskTypeDefectsAndViolations',
  components: {
    DefectCard,
    TaskActions,
    DateField,
    BuildingStandardField,
    DefectKindField,
    InputField,
    UserField,
    PlaceField,
    FileField,
    TagField,
    MemberField,
    LotDefectField,
    
    TaskPhotosGallery,
    TaskCaptures
  },
  mixins: [
    loadable({ on: 'tasks', name: 'updateTaskWithError' }),
    loadable({ on: 'tasks', name: 'storeDefectImages' })
  ],
  props: {
    task: { type: Object, required: true },
    allowedActions: { type: Array, default: () => [] },
    dependent: { type: Boolean, default: false }
  },
  data() {
    return {
      members: [],

      editedItem: {
        expired_at: null,
        inspectors: [],
        place: null,
        defect_types: [],
        custom_defect_type: null,
        description: null,
        name: '',
        kind_defect: '',
        comment_worker: '',
        lot_defect: null
      },
      objectsBuildingOrderConnection: [],
      objectsAcceptanceOfWorkConnection: [],
      errors: {},
      oldLoading: false,

      tags: [],
      photos: [],

      // gallery
      shouldDisplayGallery: false,
      shouldDisplayCaption: false,
      galleryIndex: 0,
      media: []
    }
  },
  computed: {
    ...mapState('tasks', { currentTask: state => state.task }),
    ...mapGetters('tasks', [
      'defectKinds', 
      'hasRequiredFields', 'isEditableField', 'isRequiredField', 'hasRequiredFieldValue', 'getRequiredFieldValues', 'editableFieldsErrorsByName'
    ]),
    ...mapGetters('project', ['project']),

    connectedTasks() {
      return [...this.objectsBuildingOrderConnection, ...this.objectsAcceptanceOfWorkConnection];
    },

    captures() {
      return getCaptures(this.task, { withLabels: true, withPrefixes: true })
    },

    loading() {
      return this.oldLoading || this.updateTaskWithErrorLoading || this.storeDefectImagesLoading
    },

    editablePhoto() {
      return this.captures.length <= 1 && (this.isEditableField('task_image_ids') || this.isEditableField('image_defining_point_id')) 
    }
  },
  watch: {
    currentTask: {
      immediate: true,
      handler: function (x) {
        this.initializeItem(x);
      }
    }
  },
  methods: {
    ...mapMutations('dialog', {showDialog: 'SHOW_DIALOG'}),
    ...mapActions('tasks', ['storeDefectImages', 'makeTaskAction', 'updateTaskWithError']),
    
    showGalleryByTask() {
      this.showGallery(0)
    },

    showGalleryByCapture(capture) {
      const i = this.captures.findIndex(({ id }) => id === capture.id)

      i !== -1 && this.showGallery(i)
    },

    showGallery(i) {
      this.media = this.captures.map(({ id, thumb, object_id, object_type }) => ({
        id,
        thumb,
        src: thumb,
        object_id,
        object_type
      }))

      this.shouldDisplayGallery = true
      this.shouldDisplayCaption = false
      this.galleryIndex = i
    },

    hideGallery() {
      this.shouldDisplayGallery = false
      this.shouldDisplayCaption = false
    },

    selectGalleryImage(image) {
      const i = this.media.findIndex(({ id }) => id === image?.id)

      this.galleryIndex = i
    },

    goToPosition(position) {
      const planId = getPlanIdFromPosition(position)
      const pointId = getPointIdFromPosition(position)
      const photoId = getPhotoIdFromPosition(position)

      planId && pointId && !photoId && this.$router.push({ name: 'project.plan', params: { planId, selection: [pointId] } })
      photoId && this.$router.push({ name: 'project.photo', params: { photoId } })
    },

    prepareData() {
      const { expired_at, inspectors, place, lot_defect } = this.editedItem

      const data = {
        ...this.editedItem,
        ...expired_at && { expired_at: formatForServer(startOfDay(expired_at), { zoned: false }) },

        ...toWorkerUpdation(this.task, { members: this.members, by: this.getRequiredFieldValues('worker_type') }),

        house_id: place?.house?.id || null,
        floor_id: place?.floor?.id || null,

        general_contractor_ids: inspectors.map(({ id }) => id),

        tags: this.tags.map(({ id, name }) => ({ id, name })),
        lot_defect_id: lot_defect?.id || null
      }

      delete data['lot_defect']
      delete data['inspectors']
      delete data['place']

      return data
    },

    update() {
      const updatablePhotos = this.photos.filter(({ id }) => id)
      const creatablePhotos = this.photos.filter(({ id }) => !id)

      this.storeDefectImages({ 
        task: this.task, 
        images: creatablePhotos.map(({ file }) => file) 
      })
        .then(ids => ({
          ...this.prepareData(),
          ...this.editablePhoto 
            && toImageUpdation(this.task, { updatable: updatablePhotos.map(({ id }) => id), creatable: ids })
        }))
        .then(payload => this.updateTaskWithError({
          taskId: this.task.id,
          payload
        }))
        .then(dialogs.success.bind(this, { message: 'Задача обновлена' }))
        .then(() => this.errors = {})
        .catch(({ messages }) => this.errors = {
          ...messages,
          'general_contractor_ids': flatCollectionError(messages, 'general_contractor_ids')
        })
    },

    // NOT REVIEWED

    initializeItem(task) {
      const { data } = task

      this.members = getMembersForSelection(task)

      this.editedItem.inspectors = getInspectorUsers(task).filter(is).map(({ id }) => ({ id, organization: {}, _isUser: true }))

      this.editedItem.name = this.getNested(task, 'name', '');
      this.editedItem.expired_at = parseAt(this.getNested(task, 'expired_at', null), { zoned: true });
      this.editedItem.description = this.getNested(task, 'description', null);
      this.editedItem.defect_types = task.data.defect_types.map(({ id }) => id);
      this.editedItem.custom_defect_type = task.data.custom_defect_type
      this.editedItem.comment_worker = this.getNested(task, 'data.comment_worker', '');
      this.editedItem.comment_initiator = data?.comment_initiator

      this.editedItem.object_id = this.editedItem.house_id || this.editedItem.floor_id || this.project.id

      this.editedItem.kind_defect = this.getNested(task, 'data.kind_defect');

      this.tags = task.data.tags || []
      this.editedItem.lot_defect = task.data.lot_defect ? { id: task.data.lot_defect.object_id, name: task.data.lot_defect.data.name } : null
      this.objectsBuildingOrderConnection = this.getNested(task, 'objects_building_order_connection', []);
      this.objectsAcceptanceOfWorkConnection = this.getNested(task, 'objects_acceptance_of_work_connection', []);

      const houseId = this.getNested(task, 'data.project_point_position.house.id', null)
        || this.getNested(task, 'data.acceptance_work_object.project_object_structure.house.id', null);
      const floorId = this.getNested(task, 'data.project_point_position.floor.id', null)
        || this.getNested(task, 'data.acceptance_work_object.project_object_structure.floor.id', null);

      this.editedItem.place = ((houseId || floorId) && { house: { id: houseId } , floor: { id: floorId } }) || null

      this.editablePhoto && 
        this.captures.length === 1 && 
        urlToFile(this.captures[0].image, { name: this.captures[0].name })
          .then(file => this.photos = [{
            id: this.captures[0].object_id,
            file,
            name: file.name
          }])
    },

    act(action) {
      const { name, allowed_fields = [] } = action

      this.errors = {}

      const go = (data = {}) => this.makeTaskAction({
        taskId: this.task.id,
        payload: {
          action: name,
          data
        }
      }).catch(fromError)

      allowed_fields.length && this.showDialog({
        dialogName: 'task-defects-and-violations-dialog',
        payload: {
          action,
          contractor: getContractorUser(this.task),
          comment: getWorkerComment(this.task)
        },
        action: go
      })

      !allowed_fields.length && (() => {
        this.oldLoading = true;

        go()
          .then(() => {
            this.errors = {}
            !is['send_for_revision_second_stage'].includes(name) && this.$router.push({ name: 'project.tasks.defects' })
          })
          .catch(({ messages }) => {
            this.errors = messages || {}
          })
          .finally(() => this.oldLoading = false)
      })()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.multipleTable.clearSelection();
    }
  }
};
</script>
